export function toBrazilianDate(date: Date) {
  if (!date) {
    return '';
  }

  const yyyy = date.getFullYear();
  const mm = date.getMonth() + 1;
  const dd = date.getDate();
  const hh = date.getHours();
  const MM = date.getMinutes();

  const formattedDate = `${(dd < 10) ? '0' + dd : dd}/${(mm < 10) ? '0' + mm : mm}/${yyyy}`;
  const formattedTime = `${(hh < 10) ? '0' + hh : hh}:${(MM < 10) ? '0' + MM : MM}`;
  
  return `${formattedDate} ${formattedTime}`;
}

export function getTodayDate(): string {
  const today = new Date();
  
  today.setHours(12, 0);

  return today.toISOString().split('T')[0];
}