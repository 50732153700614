import { toast } from "react-toastify";


export function parseError(error: any) {
  if (error.response) {
    if (error.response.data.message) {
      toast.error(error.response.data.message);
    }
    else if (error.response.data.messages) {
      for (let message of error.response.data.messages) {
        toast.error(message);
      }
    }
    else {
      toast.error(error.response.data);
    }
  }
  else {
    toast.error(error.message);
  }

  console.error(error);
}
