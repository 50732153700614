import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom'


const AppPage = () => {

  const { pathname, hash, key } = useLocation();

  useEffect(() => {
   window.location.href = `takere://${pathname.replace('/app/', '')}`
  }, [pathname, hash, key]);

  return (
    <></>
  );
};

export default AppPage;